@import "src/theme/variables.scss";

html,
body {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Averta-Regular"),
    url(../../assets/fonts/Averta-normal.woff2) format("woff2");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Averta-Extrabold"),
    url(../../assets/fonts/Averta-ExtraBold.woff2) format("woff2");
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $background;
  bottom: 0;
  color: $fontColor;
  font-family: "Averta", monospace;
  font-size: $mediumFontSize;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  text-rendering: geometricPrecision;
  top: 0;
}

body > div:first-child {
  display: flex;
  min-height: 100%;
}

html[class="darkMode"],
html[class="darkMode"] iframe,
html[class="darkMode"] div[style*="background-image"],
html[class="darkMode"] svg[color],
html[class="darkMode"] img:not([id="safe-logo"]),
html[class="darkMode"] [class*="networkLabel"] {
  filter: invert(0.9) hue-rotate(180deg);
}

html[class="darkMode"] [class*="networkLabel"] {
  outline: 1px solid #333;
}
