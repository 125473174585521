@import "src/theme/variables.scss";

.btnLink {
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-family: inherit;
}

.sm {
  font-size: $smallFontSize;
}

.md {
  font-size: $mediumFontSize;
}

.lg {
  font-size: $largeFontSize;
}

.xl {
  font-size: $extraLargeFontSize;
}

.xxl {
  font-size: $xxlFontSize;
}

.light {
  font-weight: $lightFont;
}

.regular {
  font-weight: $regularFont;
}

.bolder {
  font-weight: $bolderFont;
}

.bold {
  font-weight: $boldFont;
}

.soft {
  color: #888888;
}

.medium {
  color: #686868;
}

.dark {
  color: black;
}

.fancy {
  color: $fancy;
}

.warning {
  color: $warning;
}

.primary {
  color: $fontColor;
}

.secondary {
  color: $secondary;
}

.disabled {
  color: $disabled;
}

.error {
  color: $error;
}

.white {
  color: white;
}